<!-- 双色球选号 -->
<template>
    <div class="tem">
        <!-- 居左 号码选择 -->
        <div class="lef">
            <div class="lef-title">
                <strong>排列3/5选号</strong>
                <!-- <select style="width: 100px" ref="col">
              <option :value="item.val" v-for="(item, i) in option" :key="i">
                {{ item.name }}
              </option>
            </select> -->
            </div>
            <div class="lef-title2">
                选择玩法：
                <select style="width: 100px" ref="col" @click="wanfa">
                    <option :value="item.val" v-for="(item, i) in option" :key="i">
                        {{ item.name }}
                    </option>
                </select>
            </div>
            <div class="zhixuan" v-if="zhixuan">
                <div class="number">
                    <div class="number-2">百位：</div>
                    <div class="ball" :class="{ active: bai.indexOf(i) == -1 }" v-for="(item, i) in 10" :key="i"
                        @click="ball1(i)">
                        {{item-1}}
                    </div>

                    <div class="number-3" @click="numberdel(1)">清除</div>
                    <div class="number-3" @click="numberdel(11)">全选</div>
                    <div class="clear"></div>
                </div>
                <div class="number">
                    <div class="number-2">十位：</div>
                    <div class="ball" :class="{ active: shi.indexOf(i) == -1 }" v-for="(item, i) in 10" :key="i"
                        @click="ball2(i)">
                        {{item-1 }}
                    </div>
                    <div class="number-3" @click="numberdel(2)">清除</div>
                    <div class="number-3" @click="numberdel(22)">全选</div>
                    <div class="clear"></div>
                </div>
                <div class="number">
                    <div class="number-2">个位：</div>
                    <div class="ball" :class="{ active: ge.indexOf(i) == -1 }" v-for="(item, i) in 10" :key="i"
                        @click="ball3(i)">
                        {{item-1 }}
                    </div>
                    <div class="number-3" @click="numberdel(3)">清除</div>
                    <div class="number-3" @click="numberdel(33)">全选</div>
                    <div class="clear"></div>
                </div>
            </div>
            <div class="zuxuan" v-if="zuxuan">
                <div class="number number1">
                    <div class="number-2">组选：</div>
                    <div class="ball" :class="{ active: zu.indexOf(i) == -1 }" v-for="(item, i) in 10" :key="i"
                        @click="ball4(i)">
                        {{item-1 }}
                    </div>
                    <div class="number-3" @click="numberdel(4)">清除</div>
                    <div class="number-3" @click="numberdel(44)">全选</div>
                    <div class="clear"></div>
                </div>
            </div>
            <div class="pl5" v-if="pl5">

                <div class="number">
                    <div class="number-2">万位：</div>
                    <div class="ball" :class="{ active: bai.indexOf(i) == -1 }" v-for="(item, i) in 10" :key="i"
                        @click="ball1(i)">
                        {{item-1}}
                    </div>

                    <div class="number-3" @click="numberdel(1)">清除</div>
                    <div class="number-3" @click="numberdel(11)">全选</div>
                    <div class="clear"></div>
                </div>
                <div class="number">
                    <div class="number-2">千位：</div>
                    <div class="ball" :class="{ active: shi.indexOf(i) == -1 }" v-for="(item, i) in 10" :key="i"
                        @click="ball2(i)">
                        {{item-1 }}
                    </div>
                    <div class="number-3" @click="numberdel(2)">清除</div>
                    <div class="number-3" @click="numberdel(22)">全选</div>
                    <div class="clear"></div>
                </div>
                <div class="number">
                    <div class="number-2">百位：</div>
                    <div class="ball" :class="{ active: ge.indexOf(i) == -1 }" v-for="(item, i) in 10" :key="i"
                        @click="ball3(i)">
                        {{item-1 }}
                    </div>
                    <div class="number-3" @click="numberdel(3)">清除</div>
                    <div class="number-3" @click="numberdel(33)">全选</div>
                    <div class="clear"></div>
                </div>
                <div class="number">
                    <div class="number-2">十位：</div>
                    <div class="ball" :class="{ active: wan.indexOf(i) == -1 }" v-for="(item, i) in 10" :key="i"
                        @click="ball0(i)">
                        {{item-1}}
                    </div>

                    <div class="number-3" @click="numberdel(5)">清除</div>
                    <div class="number-3" @click="numberdel(55)">全选</div>
                    <div class="clear"></div>
                </div>
                <div class="number">
                    <div class="number-2">个位：</div>
                    <div class="ball" :class="{ active: qian.indexOf(i) == -1 }" v-for="(item, i) in 10" :key="i"
                        @click="ball00(i)">
                        {{item-1}}
                    </div>

                    <div class="number-3" @click="numberdel(6)">清除</div>
                    <div class="number-3" @click="numberdel(66)">全选</div>
                    <div class="clear"></div>
                </div>
            </div>
            <div class="condition" style="margin-top: 10px">
                <!-- <div class="condition-1" @click="Rotationmatrix()">旋转矩阵</div> -->
                <div class="condition-2">
                    <ul>
                        <li @click="Courage()">胆码组</li>
                        <li>-----</li>
                        <li>-----</li>
                        <li>-----</li>
                        <li>-----</li>
                        <li>-----</li>
                        <li>-----</li>
                        <li>-----</li>
                        <li>-----</li>
                        <li>-----</li>
                    </ul>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <!-- 居中 过滤条件-->
        <div class="con">
            <div class="con-title">过滤条件</div>
            <div class="con-1">
                <strong>条件显示区</strong>
                <ul>
                    <li class="con-list">
                        <div class="con-1a" v-for="(item, i) in condition" :key="i">
                            <div class="con-1aa">胆码组{{ item.dmz }}</div>
                            <div class="con-1ab">
                                <div class="con-1ab" @click="del(i, item)">删除</div>
                                <div class="con-1ab" @click="rev(i)">修改</div>
                            </div>
                            <div class="con-1ac">{{ item.dm }}出{{ item.gs }}个</div>
                            <div class="clear"></div>
                        </div>
                    </li>
                </ul>

            </div>
            <div class="con-2">
                <ul>
                    <!-- <li @click="test ()">
                <input type="file" ref="fileinput" v-show="false" name="fileContent">
              </li> -->
                    <input id="filePath" name="filePath" ref="fileinput" v-show="false" type="file" />
                    <!-- <input id="filePath" class="filepath" name="filePath" ref="fileinput"  type="file"><br> -->
                    <li id="btn" @click="doUpload">导入条件</li>
                    <li @click="save()">保存条件</li>
                    <li @click="reset()">重置条件</li>
                    <li class="zhixing" @click="filter()">执行过滤</li>
                </ul>

                <div class="clear"></div>
            </div>
        </div>
        <!-- 居右 过滤结果-->
        <div class="rig">
            <div class="rig-title">过滤结果</div>
            <div class="rig-1">
                <ssqlist ref="ssqlist" @money="mon"></ssqlist>
                <!-- @fData="ssqlist_1" -->
            </div>
            <div class="rig-2">
                <div class="rig-21">
                    <button @click="savetxt(sele)">保 存 号 码</button>
                </div>
                <div class="rig-22">
                    <button @click="doUpload2">导 入 号 码</button>
                </div>
                <!-- <div class="rig-23">
                    <button @click="windblue1">蓝 球 处 理</button>
                </div> -->
                <input id="filePath2" name="filePath2" ref="fileinput2" v-show="false" type="file" />
                <div class="clear"></div>
            </div>
            <div class="rig-3">
                <div class="rig-31">总计：{{ money }}注</div>
                <div class="rig-32">合计：{{ money * 2 }}元</div>
            </div>
        </div>
        <!-- <form action="" enctype="multipart/form-data">//暂未使用
          <input id="file" class="filepath" @click="changepic(this)" type="file"><br>
          <img src="" id="show" width="500">
        </form> -->
        <!-- <video width='500' height='300' id='video' controls></video>//暂未使用 -->

        <!-- <upload></upload> -->
        <div class="clear"></div>
        <!-- :inputname 为 props[]父组件向子组件传值,@closeDialog="close" 子组件给父组件关闭信号, closeDialog为子组件函数名称或标记（与父组件连接桥梁），close为父组件方法，false是传递的数据-->
        <wind :inputname="condition" @closeDialog="close" @func="getMsgFormSon" ref="wind" :dd="arr3" :wanf="wanf"
            v-if="wind">
        </wind>
        <!-- <windmatrix :zi="matrix" @func="matrix1" @closeDialog="close" @close="close2" v-if="windmatrix"></windmatrix> -->
        <!-- <windblue @closeDialog="close" @close="close2" v-if="windblue"></windblue> -->
    </div>
</template>

<script>
    //import api2 from "@/js/FileSaver.js"
    // import { onDeactivated } from 'vue'
    //import api from "@/js/mpublic.js"; //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    import wind from "@/components/Number-select/pl35-num/pl35-wind.vue";
    // import upload from "@/components/Number-select/klb-num/upload.vue";
    // import windmatrix from "@/components/Number-select/ssq-num/ssq-wind-matrix.vue";
    // import windblue from "@/components/Number-select/ssq-num/ssq-wind-blue.vue";
    import ssqlist from "@/components/Number-select/ssq-num/ssq-list.vue";
    //import bus from '@/js/mitt'
    // import $ from 'jquery'
    export default {
        name: "",
        components: {
            ssqlist,
            wind,
        },
        data() {
            // 数据源
            return {
                wan: [],
                qian: [],
                bai: [],
                shi: [],
                ge: [],
                zu: [],
                zhixuan: true,
                zuxuan: false,
                pl5: false,
                // arr: [], //下标/选
                // arr0: [], //号码/选
                // arr1: [], //投注方式
                sele: [], //号码数据
                // arr2: [], //计算号码
                arr3: [], //当前条件修改项
                lan: [],//蓝球处理
                wanf: [0],//当前玩法
                // matrix: [], //矩阵条件显示
                condition: [],
                wind: false,
                // windmatrix: false,
                // windblue: false,
                // ok: false, //矩阵条件显示
                // yi: [],
                // er: [],
                // img: [],
                //how: [3],
                money: [],//显示list组件中的记录总金额
                option: [
                    { name: "排列3直选", val: "0" },
                    { name: "排列3组选3", val: "1" },
                    { name: "排列3组选6", val: "2" },
                    { name: "排列5", val: "5" },

                ],
            };
        },
        // created() {//session监听
        //   window.addEventListener('setItem', () => {
        //     this.newVal = sessionStorage.getItem('watchStorage');
        //   })
        // },
        mounted() {

        },

        computed: {
            // sd() {
            //     return this.$refs.col.value;
            // },

        },

        // 函数体部分，js的主要逻辑控制
        methods: {
            //选择直选组选
            wanfa() {
                this.wanf = this.$refs.col.value;
            },
            //清除按钮
            numberdel(arr) {
                var x = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                if (arr == 1) {
                    this.bai = []
                } else if (arr == 2) {
                    this.shi = []
                } else if (arr == 3) {
                    this.ge = []
                } else if (arr == 4) {
                    this.zu = []
                } else if (arr == 11) {
                    this.bai = x
                } else if (arr == 22) {
                    this.shi = x
                } else if (arr == 33) {
                    this.ge = x
                } else if (arr == 44) {
                    this.zu = x
                } else if (arr == 5) {
                    this.wan = []
                } else if (arr == 6) {
                    this.qian = []
                } else if (arr == 55) {
                    this.wan = x
                } else if (arr == 66) {
                    this.qian = x
                }
            },
            //上传图片及视频/暂未使用
            // getObjectURL(file) {
            //     console.log(file);
            //     var url = null;
            //     // 下面函数执行的效果是一样的，只是需要针对不同的浏览器执行不同的 js 函数而已
            //     if (window.createObjectURL != undefined) {
            //         // basic
            //         url = window.createObjectURL(file);
            //     } else if (window.URL != undefined) {
            //         // mozilla(firefox)
            //         url = window.URL.createObjectURL(file);
            //     } else if (window.webkitURL != undefined) {
            //         // webkit or chrome
            //         url = window.webkitURL.createObjectURL(file);
            //     }
            //     return url;
            // },

            // 调用上传图片及视频/暂未使用
            // changepic() {
            //     //console.log(obj.files[0]);//这里可以获取上传文件的name
            //     // 转换格式
            //     var files = this.$refs.fileinput.files;
            //     var newsrc = this.getObjectURL(files[0]);
            //     console.log(newsrc);
            //     // 赋值
            //     // 视频
            //     // document.getElementById('video').src = newsrc;
            //     // 图片
            //     document.getElementById("show").src = newsrc;
            // },

            //关闭弹窗
            close() {
                this.wind = false;
                this.windmatrix = false;
                this.windblue = false;
            },
            close2() {
                this.ok = false;
            },
            //万位号码点击选择函数
            ball0(i) {
                if (this.wan.indexOf(i) == -1) {
                    this.wan.push(i);
                } else {
                    this.wan.splice(this.wan.indexOf(i), 1);
                }
            },
            //千位号码点击选择函数
            ball00(i) {
                if (this.qian.indexOf(i) == -1) {
                    this.qian.push(i);
                } else {
                    this.qian.splice(this.qian.indexOf(i), 1);
                }
            },
            //百位号码点击选择函数
            ball1(i) {
                if (this.bai.indexOf(i) == -1) {
                    this.bai.push(i);
                } else {
                    this.bai.splice(this.bai.indexOf(i), 1);
                }
            },
            //十位号码点击选择函数
            ball2(i) {
                if (this.shi.indexOf(i) == -1) {
                    this.shi.push(i);
                } else {
                    this.shi.splice(this.shi.indexOf(i), 1);
                }
            },
            //个位号码点击选择函数
            ball3(i) {
                if (this.ge.indexOf(i) == -1) {
                    this.ge.push(i);
                } else {
                    this.ge.splice(this.ge.indexOf(i), 1);
                }
            },
            //组选号码点击选择函数
            ball4(i) {
                if (this.zu.indexOf(i) == -1) {
                    this.zu.push(i);
                } else {
                    this.zu.splice(this.zu.indexOf(i), 1);

                }
            },

            //执行过滤
            filter() {
                var select = this.$refs.col.value;
                var arr = []
                arr.push(this.bai)
                arr.push(this.shi)
                arr.push(this.ge)
                if (select == '0') {
                    if (this.bai == '') {
                        this.sele = []
                        alert('百位没有选择号码')
                    } else if (this.shi == '') {
                        this.sele = []
                        alert('十位没有选择号码')
                    } else if (this.ge == '') {
                        this.sele = []
                        alert('个位没有选择号码')
                    } else {
                        this.sele = []
                        this.sele = this.getArrayByArrays(arr)
                        if (this.condition != '') {
                            this.pl_5()
                        }
                    }
                    this.clickParent()
                } else if (select == '1') {
                    if (this.zu.length < 2) {
                        this.sele = []
                        alert('选号个数不能低于2个')
                    } else {
                        this.sele = []
                        this.sele = this.san()
                        if (this.condition != '') {
                            this.pl_5()
                        }
                    }
                    this.clickParent()
                } else if (select == '2') {
                    if (this.zu.length < 3) {
                        this.sele = []
                        alert('选号个数不能低于3个')
                    } else {
                        this.sele = []
                        this.sele = this.liu()
                        if (this.condition != '') {
                            this.pl_5()
                        }

                    }
                    this.clickParent()
                } else if (select == '5') {
                    arr.push(this.wan)
                    arr.push(this.qian)
                    if (this.bai == '') {
                        this.sele = []
                        alert('万位没有选择号码')
                    } else if (this.shi == '') {
                        this.sele = []
                        alert('千位没有选择号码')
                    } else if (this.ge == '') {
                        this.sele = []
                        alert('百位没有选择号码')
                    } else if (this.wan == '') {
                        this.sele = []
                        alert('十位没有选择号码')
                    } else if (this.qian == '') {
                        this.sele = []
                        alert('个位没有选择号码')
                    } else {
                        this.sele = []
                        this.sele = this.getArrayByArrays(arr)
                        if (this.condition != '') {
                            this.pl_5()
                        }
                    }
                    this.clickParent()
                }
            },

            //组三号码组合
            san() {
                var arr = []
                for (var a = 0; a < this.zu.length; a++) {
                    for (var b = a + 1; b < this.zu.length; b++) {
                        var arr0 = []
                        var arr1 = []
                        arr0.push(this.zu[a], this.zu[a], this.zu[b])
                        arr1.push(this.zu[a], this.zu[b], this.zu[b])
                        arr.push(arr0, arr1)
                    }
                }
                //console.log(arr)
                return arr
            },
            //组六号码组合
            liu() {
                var arr = []
                for (var a = 0; a < this.zu.length; a++) {
                    for (var b = a + 1; b < this.zu.length; b++) {
                        for (var c = b + 1; c < this.zu.length; c++) {
                            var arr0 = []
                            arr0.push(this.zu[a], this.zu[b], this.zu[c])
                            arr.push(arr0)
                        }
                    }
                }
                return arr
            },
            //条件过滤1
            pl_5() {
                var xx = []
                for (var a = 0; a < this.condition.length; a++) {
                    xx = this.pl_51(xx, a)
                }
                if (xx == '') {
                    alert('对不起！没有符合条件的号码！')
                    this.sele = []
                } else {
                    this.sele = xx
                }

            },
            //条件过滤2
            pl_51(arr, a) {
                var xx = []
                if (arr == '') {
                    this.sele.map((e) => {
                        var x = 0
                        e.filter((f) => {
                            if (this.condition[a].dm.indexOf(Number(f)) != -1) {
                                x++
                            }
                        })
                        if (this.condition[a].gs.indexOf(x) != -1) {
                            xx.push(e.map(Number))
                        }
                    })
                    return xx
                } else {
                    arr.map((e) => {
                        var x = 0
                        e.filter((f) => {
                            if (this.condition[a].dm.indexOf(Number(f)) != -1) {
                                x++
                            }
                        })
                        if (this.condition[a].gs.indexOf(x) != -1) {
                            xx.push(e.map(Number))
                        }
                    })
                    return xx
                }

            },
            //直选对条件出号个数进行组合
            getArrayByArrays(arrays) {
                var arr = [""];
                for (var i = 0; i < arrays.length; i++) {
                    arr = this.getValuesByArray(arr, arrays[i]);
                }
                return arr;
            },
            //直选对条件出号个数进行组合
            getValuesByArray(arr1, arr2) {
                var arr = [];
                for (var i = 0; i < arr1.length; i++) {
                    var v1 = arr1[i];
                    for (var j = 0; j < arr2.length; j++) {
                        var v2 = [];
                        v2 = arr2[j];
                        var value = [];
                        //console.log(value.concat(v1).length)
                        arr.push(value.concat(v1.concat(v2)));

                        //console.log(typeof(value2))
                        //value ,v2,v1
                    }
                }
                return arr;
            },

            //弹窗/胆码组
            Courage() {
                this.wind = true;
            },
            //接收子组件胆码组弹窗传过来的数据
            getMsgFormSon(arr) {
                this.condition = [];
                // var array=[]//对子组件传过来的胆码组进行排序去重\
                this.arr = [];
                this.arr0 = [];
                for (var i = 0; i < arr.length; i++) {
                    var dmz = arr[i].dmz;
                    var dm = arr[i].dm;
                    var gs = arr[i].gs;
                    var hmxb = arr[i].hmxb;

                    // for (var y = 0; y < arr[i].dm.length; y++) {
                    //     if (this.arr0.indexOf(arr[i].dm[y]) == -1) {
                    //         this.arr0.push(arr[i].dm[y]);
                    //         this.arr.push(arr[i].dm[y] - 1);
                    //     }
                    // }

                    var obj = {
                        dmz: dmz,
                        dm: dm,
                        gs: gs,
                        hmxb: hmxb,
                    };
                    this.condition.push(obj);
                    for (var j = 0; j < this.condition.length; j++) {
                        //删除条件后对新的条件进行序号更新
                        if (this.condition[j].dmz !== j) this.condition[j].dmz = j;
                    }
                }
            },
            //条件修改
            rev(i) {
                this.wind = true;
                this.arr3 = [];
                this.arr3.push(i);
            },
            //胆码组删除条件数据
            del(i) {
                //更新号码显示
                // for (var y = 0; y < this.arr0.length; y++) {
                //     for (var j1 = 0; j1 < this.condition[i].dm.length; j1++) {
                //         if (this.arr0[y] == this.condition[i].dm[j1]) {
                //             this.arr0.splice(y, 1);
                //         }
                //         if (this.arr[y] == this.condition[i].dm[j1] - 1) {
                //             this.arr.splice(y, 1);
                //         }
                //     }
                // }
                //删除条件数据
                this.condition.splice(i, 1);
                this.getMsgFormSon(this.condition);
                for (var j = 0; j < this.condition.length; j++) {
                    //删除条件后对新的条件进行序号更新
                    if (this.condition[j].dmz !== j) this.condition[j].dmz = j;
                }
            },
            //@删除
            del2() {
                this.matrix = [];
                this.ok = false;
            },
            //调用子组件方法传递组合数据 /执行过滤
            clickParent() {
                this.$refs.ssqlist.parentHandleclick(this.sele, this.lan);
            },
            //保存号码
            savetxt(arr) {
                if (arr == "" || arr == null) {
                    alert("没有需要保存的号码！");
                } else {
                    var filename = +new Date() + ".txt";
                    var text = [];
                    for (var i = 0; i < arr.length; i++) {
                        text.push(arr[i] + "/");
                    }
                    //内容  换行用 \n
                    var element = document.createElement("a");
                    element.setAttribute(
                        "href",
                        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
                    );
                    element.setAttribute("download", "haoma" + filename);
                    element.style.display = "none";
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }
            },
            //重置按钮
            reset() {
                this.condition = [];
                this.matrix = [];
                this.arr = [];
                this.arr0 = [];
            },
            //保存条件
            save() {
                var arr = [];
                if (this.condition != null || this.condition != "") {
                    for (var a = 0; a < this.condition.length; a++) {
                        var obj = [
                            "dmz",
                            this.condition[a].dmz + "/",
                            "dm",
                            this.condition[a].dm + "/",
                            "gs",
                            this.condition[a].gs + "/ssq",
                        ];
                        arr.push(obj);
                    }
                }
                // if (this.matrix.length > 0 && this.ok == true) {
                //     var obj0 = ["jz", this.selec() + "/ssq"];
                //     arr.push(obj0);
                // }
                if (arr == "" || arr == null) {
                    alert("对不起,没有需要保存的条件！");
                } else {
                    var filename = +new Date() + ".txt";
                    var text = [];
                    for (var i = 0; i < arr.length; i++) {
                        text.push(arr[i]);
                    }
                    //内容  换行用 \n
                    var element = document.createElement("a");
                    element.setAttribute(
                        "href",
                        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
                    );
                    element.setAttribute("download", "tiaojian" + filename);
                    element.style.display = "none";
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                }
            },
            //导入条件 监听
            doUpload() {
                const fileNode = document.getElementById("filePath");
                fileNode.addEventListener("change", this.test);
                //fileNode.addEventListener("change", this.changepic );
                fileNode.click();
            },
            //导入条件 读取
            test() {
                var files = this.$refs.fileinput.files;
                if (files.length) {
                    var name = files[0].name;
                    var ss = name.substr(name.lastIndexOf(".")).toUpperCase(); //得到的是后缀名,且转换为大写
                    if (ss == ".TXT") {
                        var reader = new FileReader(); //new一个FileReader实例
                        if (typeof FileReader == "undefined") {
                            alert("你的浏览器暂不支持该功能");
                            return;
                        }
                        reader.readAsText(files[0]);
                        let self = this;
                        reader.onload = (ev) => {
                            if (
                                ev.target.result.substr(
                                    ev.target.result.length - 4,
                                    ev.target.result.length
                                ) == "/ssq"
                            ) {
                                var relArr = ev.target.result
                                    .substr(0, ev.target.result.length - 4)
                                    .split("/ssq,");
                                var zdm = [];
                                var zdmxb = [];
                                for (var a = 0; a < relArr.length; a++) {
                                    var dmz = [];
                                    var dm = [];
                                    var gs = [];
                                    var jz = [];
                                    var dmxb = [];
                                    var relArr2 = relArr[a].split("/,");
                                    // console.log(relArr[a].split(',')[0])
                                    if (relArr[a].split(",")[0] == "dmz") {
                                        for (var b = 0; b < relArr2.length; b++) {
                                            var relArr3 = relArr2[b].split(",");
                                            for (var c = 0; c < relArr3.length - 1; c++) {
                                                if (relArr3[0] == "dmz") {
                                                    dmz.push(relArr3[c + 1]);
                                                }
                                                if (relArr3[0] == "dm") {
                                                    dm.push(relArr3[c + 1]);
                                                    dmxb.push(relArr3[c + 1] - 1);
                                                }
                                                if (relArr3[0] == "gs") {
                                                    gs.push(relArr3[c + 1]);
                                                }
                                            }
                                        }

                                        if (dmz != "" || dmz != null) {
                                            var obj = {
                                                dmz: dmz.map(Number),
                                                dm: dm.map(Number),
                                                gs: gs.map(Number),
                                            };
                                            zdm = zdm.concat(dm.map(Number));
                                            zdmxb = zdmxb.concat(dmxb.map(Number));
                                            self.condition.push(obj);
                                        }
                                    } else if (relArr[a].split(",")[0] == "jz") {
                                        for (var d = 0; d < relArr2.length; d++) {
                                            var relArr4 = relArr2[d].split(",");
                                            // console.log(relArr3)
                                            for (var e = 0; e < relArr4.length - 1; e++) {
                                                if (relArr4[0] == "jz") {
                                                    jz.push(relArr4[e + 1]);
                                                }
                                            }
                                        }

                                        if (jz != "" || jz != null) {
                                            self.matrix = jz.map(Number);
                                            self.ok = true;
                                            this.$refs.col.value = jz.map(Number);
                                        }
                                    } else {
                                        alert("对不起！文件内容不正确！");
                                    }
                                }
                                self.arr = zdmxb;
                            } else {
                                alert("此条件不是双色球玩法");
                            }

                            // self.arr0=zdm总胆码组数据未使用
                        };
                    } else {
                        alert("文件格式不支持!");
                    }
                }
                const fileNode = document.getElementById("filePath");
                fileNode.removeEventListener("change", this.test);
            },
            //导入号码
            doUpload2() {
                const fileNode = document.getElementById("filePath2");
                fileNode.addEventListener("change", this.test2);
                //fileNode.addEventListener("change", this.changepic );
                fileNode.click();
            },
            //导入号码 读取
            test2() {
                var files = this.$refs.fileinput2.files;
                // var regp = new RegExp(".*,\".*,.*\"$")
                // console.log(files[0])
                if (files.length) {
                    var name = files[0].name;
                    var ss = name.substr(name.lastIndexOf(".")).toUpperCase(); //得到的是后缀名,且转换为大写
                    if (ss == ".TXT") {
                        var reader = new FileReader(); //new一个FileReader实例
                        if (typeof FileReader == "undefined") {
                            alert("你的浏览器暂不支持该功能");
                            return;
                        }
                        reader.readAsText(files[0]);
                        let self = this;
                        reader.onload = (ev) => {
                            if (ev.target.result[ev.target.result.length - 1] == "/") {
                                var relArr = ev.target.result
                                    .substr(0, ev.target.result.length - 1)
                                    .split("/,");
                                // var relArr2=relArr[relArr.length-1].replace('/','')
                                self.sele = relArr;
                                self.clickParent();
                            } else {
                                alert("对不起！文件内容不正确！");
                            }
                        };
                    } else {
                        alert("文件格式不支持!");
                    }
                }
                const fileNode = document.getElementById("filePath");
                fileNode.removeEventListener("change", this.test);
            },

            // 蓝球处理弹窗
            windblue1() {
                if (this.sele.length != 0) {
                    this.windblue = true;
                } else {
                    alert("没有需要处理的蓝球");
                }
            },

            mon(arr) {//接收list子组件回传的记录数
                this.money = arr
            },

            show(relArr) {
                console.log(relArr);
                console.log("测试一下");
                //var relArr = this.result.split('//,')
                for (var a = 0; a < relArr.length; a++) {
                    var relArr2 = relArr[a].split("/,");
                    var dmz = [];
                    var dm = [];
                    var gs = [];
                    for (var b = 0; b < relArr2.length; b++) {
                        var relArr3 = relArr2[b].split(",");
                        // console.log(relArr3)
                        for (var c = 0; c < relArr3.length - 1; c++) {
                            if (relArr3[0] == "dmz") {
                                dmz.push(relArr3[c + 1]);
                            }
                            if (relArr3[0] == "dm") {
                                dm.push(relArr3[c + 1]);
                            }
                            if (relArr3[0] == "gs") {
                                gs.push(relArr3[c + 1]);
                            }
                        }
                    }
                    if (dmz != "" || dmz != null) {
                        var obj = {
                            dmz: dmz,
                            dm: dm,
                            gs: gs,
                        };
                        //arr.push(obj)
                        console.log("2");
                        console.log(obj);

                        this.condition = obj;
                    }
                }
            },
        },

        watch: {
            wanf() {
                if (this.wanf == '0') {
                    this.bai = []
                    this.shi = []
                    this.ge = []
                    this.zhixuan = true
                    this.zuxuan = false
                    this.pl5 = false
                } else if (this.wanf == '5') {
                    this.wan = []
                    this.qian = []
                    this.bai = []
                    this.shi = []
                    this.ge = []
                    this.zhixuan = false
                    this.zuxuan = false
                    this.pl5 = true
                } else {
                    this.zu = []
                    this.zhixuan = false
                    this.zuxuan = true
                    this.pl5 = false
                }
            },

        },

        props: {},
    };
</script>

<style scoped>
    /* @import './swiper.css';  */
    /* * {
        border: 1px solid red;
    } */
    .zhixuan {
        height: 250px;

    }

    .zuxuan {
        height: 250px;

    }

    .tem {
        width: 100%;
        border: 1px solid rgb(214, 213, 213);
    }

    .lef {
        float: left;
        width: 40%;
        height: 500px;
        border: 1px solid rgb(214, 213, 213);

    }

    .lef-title {
        width: 100%;
        height: 50px;
        font-size: 16px;
        line-height: 47px;
        border-radius: 10px;
        color: rgb(116, 116, 116);
        background-color: rgb(240, 238, 238);
    }

    .lef-title2 {
        width: 100%;
        height: 50px;
        font-size: 16px;
        line-height: 47px;
        border-radius: 10px;

        background-color: rgb(253, 250, 250);
    }

    .number {
        width: 100%;
        margin: 0 auto;
        margin-top: 5px;
        border-radius: 10px;
        border: 1px solid rgb(214, 213, 213);
    }

    .number1 {
        margin-top: 100px;
    }

    .number-2 {
        float: left;
        line-height: 35px;
        font-size: 16px;
    }

    .number-3 {
        font-size: 14px;
        width: 50px;
        float: right;
        line-height: 35px;
    }

    .condition {
        width: 100%;
    }

    .condition-1 {
        width: 50%;
        margin: 0 auto;
        height: 50px;
        margin-top: 20px;
        line-height: 47px;
        font-size: 20px;
        background-color: rgb(250, 3, 3);
        border-radius: 15px;
        color: #fff;
    }

    .condition-2 {
        width: 100%;
        margin-top: 30px;
        border: 1px solid rgb(213, 214, 213);
    }

    .condition-2 ul li {
        float: left;
        width: 80px;
        height: 30px;
        line-height: 27px;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 20px;
        border-radius: 5px;
        background-color: rgb(223, 221, 221);
        border: 1px solid rgb(186, 187, 186);
        color: rgb(119, 114, 114);
    }

    .con {
        float: left;
        width: 20%;
        height: 500px;
        line-height: 48px;
        border: 1px solid rgb(238, 236, 236);
    }

    .con-title {
        width: 100%;
        height: 50px;
        font-size: 16px;
        line-height: 47px;
        border-radius: 10px;
        color: rgb(116, 116, 116);
        background-color: rgb(240, 238, 238);
    }

    .con-1 {
        width: 100%;
        height: 350px;
        overflow: auto;
        border: 1px solid rgb(186, 187, 186);
    }

    .con-1 ul li {
        border: 1px solid rgb(216, 218, 216);
    }

    .con-1a {
        line-height: 15px;
        border: 1px solid rgb(212, 210, 210);
    }

    .con-1b {
        line-height: 15px;
    }

    .con-1aa {
        float: left;
        width: 40%;
        font-weight: bold;
    }

    .con-1ab {
        float: right;
        width: 40%;
    }

    .con-1ac {
        float: left;
        width: 100%;
    }

    .con-2 ul li {
        float: left;
        width: 100px;
        height: 30px;
        line-height: 27px;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 7%;
        border-radius: 5px;
        background-color: rgb(223, 221, 221);
        border: 1px solid rgb(186, 187, 186);
        color: rgb(119, 114, 114);
    }

    .con-2 ul .zhixing {
        background: rgb(219, 4, 4);
        color: #fff;
        font-weight: bold;
    }

    .rig {
        float: left;
        width: 39%;
        height: 500px;
        border: 1px solid rgb(214, 213, 213);
    }

    .rig-title {
        width: 100%;
        height: 50px;
        font-size: 16px;
        line-height: 47px;
        border-radius: 10px;
        color: rgb(116, 116, 116);
        background-color: rgb(240, 238, 238);
    }

    .rig-1 {
        width: 100%;
        height: 350px;
        border: 1px solid rgb(186, 187, 186);
    }

    .rig-2 {
        width: 100%;
        margin-top: 10px;
    }

    .rig-21 {
        float: left;
        width: 48%;
    }

    .rig-22 {
        float: left;
        width: 48%;
    }

    .rig-23 {
        float: left;
        width: 32%;
    }

    .rig-3 {
        width: 58%;
        float: right;

        text-align: left;
    }

    .rig-32 {
        color: red;
        font-weight: bold;
    }

    .ball {
        float: left;
        margin-left: 6px;
        margin-top: 6px;
        line-height: 26px;
        width: 28px;
        height: 28px;
        font-size: 15px;
        border-radius: 15px;
        color: #fff;
        font-weight: bold;
        /* border: 1px solid red; */
        background-color: rgb(219, 133, 3);
    }

    .active {
        color: rgb(124, 122, 122);
        font-weight: bold;
        /* border: 1px solid red; */
        background-color: rgb(212, 211, 211);
    }

    .con-list {
        overflow: auto;
    }

    .clear {
        clear: both;
    }
</style>