<!-- 号码显示动态列表 -->
<template>
    <div class="list_pic">
        <!-- 标题 -->
        <div class="list-aa">
            <div class="list-aa1">
                序号
            </div>
            <div class="list-aa2">
                号码
            </div>
            <div class="clear"></div>
        </div>
        <!-- 列表 -->
        <div class="list_a ">
            <ul>
                <div class="rou_a" v-for="(item, i) in currentPageData" :key="i">
                    <li class="list-a1">
                        {{ currentPage*20-20+i+1}}
                    </li>
                    <li class="list-a2">
                        {{item.toString()}}
                    </li>

                    <div class="clear"></div>
                </div>

            </ul>
        </div>
        <!-- 分页按钮 -->
        <div class="list_b">
            <footer>
                <button style="margin-right:10px" @click="frontpage()">
                    首 页
                </button>
                <button @click="prevPage()">
                    上一页
                </button>
                <span>第{{currentPage}}页/共{{totalPage}}页</span>
                <button @click="nextPage()">
                    下一页
                </button>
                <button style="margin-left:10px" @click="Tail()">
                    尾 页
                </button>
            </footer>
        </div>

    </div>
</template>

<script>
    //import Pagination from '@/components/Pagination.vue'
    //import api from "@/js/mpublic.js";

    export default {
        name: 'ssq-list',
        components: {
            // Pagination
        },
        data() { // 数据源
            return {
                arr0: [],//红球数据
                lan: [],//蓝球数据
                i1: [],//当前显示的蓝球
                record: [],
                totalPage: 1, // 总共页数，默认为1
                currentPage: 1, //当前页数 ，默认为1
                pageSize: 20, // 每页显示数量
                currentPageData: [], //当前页显示内容
                count: [0],
                last: [],
                start: [],
                jf: [],
            };
        },
        mounted() {

        },

        computed: {


        },

        methods: {
            //循环使用
            xh(start, stop) {
                var arr = []
                if (stop < this.arr0.length) {
                    for (var a0 = start; a0 < stop; a0++) {
                        arr.push(this.arr0[a0].concat('---' + this.lan.data[a0 % this.lan.data.length]))
                    }
                } else {
                    for (var a1 = start; a1 < this.arr0.length; a1++) {
                        arr.push(this.arr0[a1].concat('---' + this.lan.data[a1 % this.lan.data.length]))
                    }
                }
                return arr
            },
            //逐一使用
            zy(start, stop) {
                var arr = []
                if (stop < this.arr0.length * this.lan.data.length) {
                    for (var a = 0; a < this.lan.data.length; a++) {
                        for (var a1 = start; a1 < stop; a1++) {
                            if (a1 == 1 && this.arr0.length == 1107568) {
                                a = 0
                            } else {
                                a = parseInt(a1 / this.arr0.length)
                            }
                            arr.push(this.arr0[a1 % this.arr0.length].concat('---' + this.lan.data[a]))
                        }
                        return arr
                    }
                } else {
                    for (var a2 = 0; a2 < this.lan.data.length; a2++) {
                        for (var a3 = start; a3 < this.arr0.length * this.lan.data.length; a3++) {
                            if (a3 == 1 && this.arr0.length == 1107568) {
                                a2 = 0
                            } else {
                                a2 = parseInt(a3 / this.arr0.length)
                            }
                            arr.push(this.arr0[a3 % this.arr0.length].concat('---' + this.lan.data[a2]))
                        }
                        return arr
                    }
                }
            },
            //平均使用
            pj(start, stop) {
                var arr = []
                if (stop < this.arr0.length) {
                    for (var a0 = start; a0 < stop; a0++) {
                        var x = []
                        if (parseInt(a0 / parseInt(this.arr0.length / this.lan.data.length)) < this.lan.data.length) {
                            x = parseInt(a0 / parseInt(this.arr0.length / this.lan.data.length))
                        } else {
                            x = this.lan.data.length - 1
                        }
                        arr.push(this.arr0[a0].concat('---' + this.lan.data[x]))
                    }
                } else {
                    for (var a1 = start; a1 < this.arr0.length; a1++) {
                        var x1 = []
                        if (parseInt(a1 / parseInt(this.arr0.length / this.lan.data.length)) < this.lan.data.length) {
                            x1 = parseInt(a1 / parseInt(this.arr0.length / this.lan.data.length))
                        } else {
                            x1 = this.lan.data.length - 1
                        }
                        arr.push(this.arr0[a1].concat('---' + this.lan.data[x1]))
                    }
                }
                return arr
            },
            //初始化首页
            parentHandleclick(fData, lan) {
                this.currentPage = 1
                this.lan = lan
                this.arr0 = fData
                // 计算一共有几页
                this.totalPage = Math.ceil(fData.length / this.pageSize);
                // 计算得0时设置为1
                this.totalPage = this.totalPage == 0 ? 1 : this.totalPage;
                let begin = (this.currentPage - 1) * this.pageSize;
                let end = this.currentPage * this.pageSize;
                this.currentPageData = fData.slice(
                    begin,
                    end
                );
                if (lan.id == '1') {
                    this.jf = fData.length * lan.data.length
                    this.totalPage = Math.ceil(fData.length * lan.data.length / this.pageSize);
                    // 计算得0时设置为1
                    this.totalPage = this.totalPage == 0 ? 1 : this.totalPage;
                    let begin = (this.currentPage - 1) * this.pageSize;
                    let end = this.currentPage * this.pageSize;
                    this.currentPageData = this.zy(
                        begin,
                        end
                    );
                } else if (lan.id == '0') {
                    this.jf = fData.length
                    this.currentPageData = this.xh(
                        begin,
                        end
                    );
                } else if (lan.id == '2') {
                    this.jf = fData.length
                    this.currentPageData = this.pj(
                        begin,
                        end
                    );
                }


            },
            // 设置当前页面数据，对数组操作的截取规则为[0~10],[10~20]...,
            setCurrentPageData() {
                let begin = (this.currentPage - 1) * this.pageSize;
                let end = this.currentPage * this.pageSize;
                this.currentPageData = this.arr0.slice(
                    begin,
                    end
                );
                if (this.lan.id == '1') {
                    this.currentPageData = this.zy(
                        begin,
                        end
                    );
                } else if (this.lan.id == '0') {
                    this.currentPageData = this.xh(
                        begin,
                        end
                    );
                } else if (this.lan.id == '2') {
                    this.currentPageData = this.pj(
                        begin,
                        end
                    );
                }


            },

            //首页
            frontpage() {
                this.currentPage = 1;
                this.setCurrentPageData(0, this.i1);


            },
            //上一页
            prevPage() {
                if (this.currentPage == 1) return;
                this.currentPage--;
                this.setCurrentPageData();
            },
            // 下一页
            nextPage() {
                if (this.currentPage == this.totalPage) return;
                this.currentPage++;
                this.setCurrentPageData();
            },
            //尾页
            Tail() {
                this.currentPage = this.totalPage;
                this.setCurrentPageData(3, this.i1);
            },


        },
        watch: {
            arr0() {
                this.$emit('money', this.arr0.length)
            },

            jf() {
                this.$emit('money1', this.jf)
            }

        },

        props: [],





    }
</script>

<style scoped>
    /* * {
        border: 1px solid red;
    } */

    .list_pic {
        width: 100%;
        float: right;
    }

    .list_a {
        height: 290px;
        OVERFLOW: auto;
    }

    .list-aa {
        background-color: rgb(216, 219, 216);
    }

    .list-aa1 {
        width: 10%;
        float: left;
    }

    .list-aa2 {
        width: 80%;
        float: left;
    }

    .list-a1 {
        float: left;
        width: 11%;
        font-size: 15px;
    }

    .list-a2 {
        text-align: left;
        font-size: 15px;
        float: left;
        letter-spacing: 1px;
        border: 1px solid rgb(208, 215, 224);

    }
</style>