<!-- 胆码组弹窗组件-->
<template>
    <div id="dialog">
        <div class="border">
            <div @click="closeDialog" class="closeImg"><svg t="1634463638558" class="icon" viewBox="0 0 1024 1024"
                    version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1908" width="100%" height="100%">
                    <path
                        d="M823.04 823.04A439.893333 439.893333 0 1 1 341.333333 106.666667a439.893333 439.893333 0 0 1 481.706667 716.373333zM512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m205.226667 306.346667a35.84 35.84 0 0 0-50.773334 0L512 460.8 357.546667 306.346667a35.84 35.84 0 0 0-51.2 50.773333L461.226667 512l-154.453334 154.88a35.84 35.84 0 1 0 51.2 50.773333L512 563.2l154.453333 154.88a35.84 35.84 0 1 0 51.2-50.773333L562.773333 512l154.453334-154.88a35.84 35.84 0 0 0 0-50.773333z"
                        fill="#5E5C5C" p-id="1909"></path>
                </svg></div>
            <div style="font-size:16px;margin-top:5px;">胆码组：</div>
            <div class="wind">
                <!-- 号码选择区 -->
                <div class="win-ball">
                    <div class="number">
                        <div class="ball" :class="{ active: arr.indexOf(i) !== -1 }" v-for="(item, i) in 10" :key="i"
                            @click="ball(i)">
                            {{ item-1}}
                        </div>
                        <div class="clear"></div>
                    </div>
                    <!-- 尾数选择区 -->
                    
                </div>
                <!-- 出号个数-->
                <div  class="win-geshu">
                    <div v-if="but1" class="win-geshu1">
                        <a>出现个数：</a>
                        <button  class="button2" @click="ball2(i)" :class="{ active1: arr1.indexOf(i) !== -1 }" v-for="(item ,i) in button" :key="i">{{item}}</button>
                    </div>
                    <div v-if="but2" class="win-geshu1">
                        <a>出现个数：</a>
                        <button  class="button2" @click="ball2(i)" :class="{ active1: arr1.indexOf(i) !== -1 }" v-for="(item ,i) in button2" :key="i">{{item}}</button>
                    </div>
                    <div class="win-geshu2">
                        <button class="button3" @click="add()" name='添加'>添加</button>
                    </div>
                </div>
                <!-- 条件显示区 -->
                <div class="win-tiaojian">
                    <div v-for="(item ,i) in  condition" :key="i">
                        <div class="win-t1">胆码组{{item.dmz}}</div>
                        <div class="win-t2">{{item.dm}}出{{item.gs}}个</div>
                        <div class=" wint3" :class="{ wint31: arr3.indexOf(i) !== -1 }" @click="revise(i,item)">修改</div>
                        <div class="win-t4" @click="del(i)">删除</div>
                    </div>
                </div>
                <div class="win-tianjia">
                    <div class="win-t5">
                        胆码组：由0到9的号码组成，选择一组用户认为要出现的号码，并设置要出现的个数，组合起来确定出号条件。例如设定胆码组号码为0,1,2，出现个数为2，则在过滤结果中的每注号码包含0,1,2中的其中两个。
                        胆码：用户认为本期开奖号码中最有可能出现的号码。
                    </div>
                    <div class="win-t6">
                        <div class="win-t7" @click="confirm()">
                            确认修改
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/js/mpublic.js'
    export default {
        name: "wind",
        data() {
            return {
                arr: [],//下标
                arr0: [],//号码
                arr1: [],//出号个数
                arr2: [],//条件序号下标更新内容标记
                arr3: [],//条件修改按钮
                button: ['0个', '1个', '2个', '3个'],
                button2: ['0个', '1个', '2个', '3个', '4个', '5个'],
                bal: [
                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10
                ],
                condition: [],
                but1:true,
                but2:false,
              
            }
        },
        mounted() {
            this.rev()
        },
        computed: {
            // sd() {
            //     return this.$store.state.sd;
            // },

        },
        methods: {

            ball(i) { //号码点击选择函数
                if (this.arr.indexOf(i) == -1) {
                    this.arr.push(i);
                } else {
                    this.arr.splice(this.arr.indexOf(i), 1);
                }
            },

            ball2(i) { //出号个数函数
                if (this.arr1.indexOf(i) == -1) {
                    this.arr1.push(i);
                    //this.arr2.push(i + 1)
                } else {
                    this.arr1.splice(this.arr1.indexOf(i), 1);
                    // this.arr2.splice(this.arr2.indexOf(i + 1), 1);
                }

            },

            add() { //条件添加按钮
                var dx=0
                if(this.wanf=='5'){
                    dx=4
                }else{
                    dx=2
                }
                var aa = document.getElementsByClassName('button3')[0].innerHTML
                if (aa == '添加') {
                    var dmz = this.condition.length  //胆码组
                    var dm = api.smallb(this.arr)//胆码
                    var gs = api.smallb(this.arr1)//出号个数
                    var zdgs = api.max(gs) //最大个数
                    var hmxb = this.arr//号码下标
                    if (dm.length !== 0 && gs.length !== 0) {
                        if (zdgs <= dm.length+dx) {
                            var obj = {
                                "dmz": dmz,
                                "dm": dm,
                                "gs": gs,
                                "hmxb": hmxb,
                            }
                            this.condition.push(obj)
                            this.arr = []
                            this.arr0 = []
                            this.arr1 = []
                        } else {
                            alert('出号个数不正确')
                        }

                    } else {
                        alert("请选择号码及出现个数！")
                    }
                } else if (aa == '保存') {//保存按钮
                    var dm2 = api.smallb(this.arr)
                    var gs2 = api.smallb(this.arr1)
                    var zdgs2 = api.max(gs2)
                    var hmxb2 = this.arr
                    if (dm2.length !== 0 && gs2.length !== 0) {
                        if (zdgs2 <= dm2.length+dx) {
                            this.condition.splice(this.arr2, 1, { "dmz": this.arr2[0], "dm": dm2, "gs": gs2, "hmxb": hmxb2, })
                            this.arr = []
                            this.arr0 = []
                            this.arr1 = []
                            this.arr3 = []
                            document.getElementsByClassName('wint3')[this.arr2].style.backgroundColor = ""
                            document.getElementsByClassName('button3')[0].innerHTML = '添加'
                        } else {
                            alert('选号个数 需要大于等于 出现个数')
                        }
                    } else {
                        alert("请选择号码及出现个数！")
                    }
                }
                //console.log(aa)
            },

            del(i) {//删除条件数据
                this.condition.splice(i, 1);
                this.arr = []//下标
                this.arr0 = []//号码
                this.arr1 = []//出号个数
                this.arr3 = []
                document.getElementsByClassName('button3')[0].innerHTML = '添加'
                for (var j = 0; j < this.condition.length; j++) {//删除条件后对新的条件进行序号更新
                    if (this.condition[j].dmz !== j)
                        this.condition[j].dmz = j
                }
            },

            revise(i, item) {//条件修改
                this.arr2 = []
                this.arr2.push(i)
                document.getElementsByClassName('button3')[0].innerHTML = '保存'
                if (this.arr !== [] || this.arr0 !== [] || this.arr1 !== []) {
                    this.arr = []
                    this.arr0 = []
                    this.arr1 = []
                    for (var j = 0; j < item.dm.length; j++) {
                        this.ball(item.hmxb[j])
                    }
                    for (var v = 0; v < item.gs.length; v++) {
                        this.ball2(item.gs[v])
                    }
                } else {
                    for (var j1 = 0; j1 < item.dm.length; j1++) {
                        this.ball(item.hmxb[j1])
                    }
                    for (var v1 = 0; v1 < item.gs.length; v1++) {
                        this.ball2(item.gs[v1])
                    }
                }
                if (this.arr3.indexOf(i) == -1) {
                    if (this.arr3 !== []) {
                        this.arr3 = []
                        this.arr3.push(i);
                    } else {
                        this.arr3.push(i);
                    }

                } else {
                    this.arr3.splice(this.arr3.indexOf(i), 1);
                    this.arr = []
                    this.arr0 = []
                    this.arr1 = []
                    document.getElementsByClassName('button3')[0].innerHTML = '添加'
                }
            },

            confirm() {//确认修改
                this.$emit('closeDialog', false)//给父组件传值，并传递数据
                this.$emit('func', this.condition)

            },

            rev() {//页面启动加载
                for (var i = 0; i < this.inputname.length; i++) {
                    var obj = {
                        "dmz": this.inputname[i].dmz,
                        "dm": this.inputname[i].dm,
                        "gs": this.inputname[i].gs,
                        "hmxb": this.inputname[i].hmxb
                    }
                    this.condition.push(obj)
                    if (this.dd == i) {
                        this.revise(i, obj)
                       
                    }
                }
                if(this.wanf=='5'){
                    this.but1=false
                    this.but2=true
                }else{
                    this.but1=true
                    this.but2=false
                }

            },

            closeDialog() {  //给父组件传参
                this.$emit('closeDialog', false)
            }
        },
        // watch: {
        //     wanfa(){
        //         if(this.wanfa=='5'){
        //             this.but1=false
        //             this.but2=true
        //         }else{
        //             this.but1=true
        //             this.but2=false
        //         }
        //     }
        // },
        props: ['inputname', 'dd','wanf'],
    }
</script>

<style scoped>
    /* *{
        border:1px solid red;
    } */
    #dialog {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
    }

    .closeImg {
        width: 30px;
        height: 30px;
        float: right;
        margin-right: 5px;
        margin-top: 5px;
        cursor: pointer;
        /* background-image: url(../../assets/close.png); */
        /* background-color:rgb(194, 192, 192); */
        background-size: cover;
        background-repeat: no-repeat;
    }

    .border {
        text-align: center;
        background-color: white;
        border-radius: 20px;
        width: 400px;
        height: 400px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }



    .wind {
        float: left;
        width: 100%;

    }

    .win-ball {
        width: 100%;
    }

    .win-geshu {
        width: 95%;
        height: 10%;
    }

    .win-geshu1 {
        width: 80%;
        float: left;
        margin-top: 7px;
    }

    .button2 {
        margin-left: 10px;
    }

    .win-geshu2 {
        width: 18%;
        height: 80%;
        float: right;
        margin-top: 5px;
    }

    .button3 {
        width: 90%;
        height: 100%;
        border: 1px solid rgb(160, 158, 158);
        border-radius: 5px;
        color: aliceblue;
        background-color: rgba(252, 153, 24, 0.993);
    }

    .win-tiaojian {
        width: 90%;
        height: 135px;
        overflow: auto;
        margin:0 auto;
        border: 1px solid rgb(226, 224, 224);
    }

    .win-tianjia {
        width: 100%;
    }

    .number {
        width: 85%;
        margin: 0 auto;
        margin-top:10px;
        border-radius: 10px;
        border: 1px solid rgb(214, 213, 213);
    }

    .ball {
        float: left;
        margin-left: 6px;
        margin-top: 6px;
        line-height: 25px;
        width: 25px;
        height: 25px;
        font-size: 12px;
        border-radius: 12px;

        color: rgb(124, 122, 122);
        font-weight: bold;
        /* border: 1px solid red; */
        background-color: rgb(212, 211, 211);
    }

    .active {
        color: rgb(255, 251, 251);
        font-weight: bold;
        /* border: 1px solid red; */
        background-color: rgb(219, 133, 3);

    }


    .button {
        border: 1px solid rgb(167, 166, 166);
    }

    .active1 {
        background-color: red;
        color: aliceblue;
    }

    .win-t1 {
        width: 15%;
        align-items: center;
        display: inline-block;
    }

    .win-t2 {
        width: 60%;
        word-break: break-all;
        display: inline-block;
    }

    .wint3 {
        width: 10%;
        display: inline-block;
    }

    .wint31 {
        width: 10%;
        display: inline-block;
        background-color: red;
        color: aliceblue;
        border-radius: 5px;
    }

    .win-t4 {
        width: 10%;
        display: inline-block;
    }

    .win-t5 {
        width: 80%;
        display: inline-block;
        word-break: break-all;
        text-align: left;

    }

    .win-t6 {
        width: 100%;
        height: 100%;
        display: inline-block;
    }

    .win-t7 {
        width: 20%;
        height: 28px;
        background-color: red;
        color: aliceblue;
        display: inline-block;
        border-radius: 5px;
        line-height: 27px;
        margin-top:10px;

    }

    .clear {
        clear: both;
    }
</style>